import React, { useEffect, useState } from "react";
import baseUrl from "./Configs";
import "./Css/AddClass.css";
import "./Css/Basics.css";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const AddClass = () => {
  const [className, setClassName] = useState();
  const [section, setSection] = useState();
  const [subjects, setSubjects] = useState([
    { subjectName: "", teacherId: "" },
  ]);
  const [teachersList, setTeachersList] = useState([]);

  let ClassDetails = {
    className: className,
    section: section,
    subjects: subjects,
  };

  const addNewClassUrl = `${baseUrl}/classroute/addnewclass`;
  const getAllTeachersUrl = `${baseUrl}/schoolroute/getallteachers`;

  let options = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(ClassDetails),
  };

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const addNewClass = async () => {
    console.log(ClassDetails);
    await fetch(addNewClassUrl, options)
      .then((data) => data.json())
      .then((jsonData) => console.log(jsonData))
      .catch((e) => console.log(e));
  };

  const addMore = () => {
    setSubjects([...subjects, { subjectName: "", teacherId: "" }]);
  };

  const handleSubjectChange = (index, event) => {
    const values = [...subjects];
    values[index] = { ...values[index], subjectName: event.target.value };
    console.log("values", values);
    setSubjects(values);
  };

  const handleTeacherChange = (index, event) => {
    const values = [...subjects];
    values[index] = { ...values[index], teacherId: event.target.value };
    setSubjects(values);
    console.log("teacher", event.target.value);
  };

  useEffect(() => {
    fetch(getAllTeachersUrl, optionsForGet)
      .then((response) => response.json())
      .then((data) => setTeachersList(data))
      .catch((e) => console.log("Failed to fetch teachers list", e));
  }, []);

  return (
    <div className="addNewClassSection">
      <div className="inputSection">
        <Box>
          <TextField
            label="Class"
            variant="filled"
            onChange={(e) => setClassName(e.target.value)}
          />
        </Box>
        <Box>
          <TextField
            label="Section"
            variant="filled"
            onChange={(e) => setSection(e.target.value)}
          />
        </Box>
      </div>

      <div>
        {subjects.map((subject, index) => (
          <div>
            <Box key={index} className="inputSection">
              <TextField
                label={`Subject ${index + 1}`}
                variant="filled"
                value={subject.subjectName}
                onChange={(event) => handleSubjectChange(index, event)}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Teacher</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subject.teacherId}
                  label="Age"
                  onChange={(e) => handleTeacherChange(index, e)}
                >
                  {teachersList.map((teacher, index) => (
                    <MenuItem key={index} value={teacher.id}>
                      {teacher.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        ))}
        <div className="orange-button" onClick={addMore}>
          Add More Subject
        </div>
      </div>
      <div className="add-button" onClick={() => addNewClass()}>
        <p>Create Class</p>
      </div>
    </div>
  );
};

export default AddClass;

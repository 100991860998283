import { atom } from "recoil";

export const selectedClassNameState = atom({
    key: 'selectedClassNameState',
    default: 3
})

export const classNameState = atom({
    key: 'classNameState',
    default: ''
})

export const selectedSectionNameState = atom({
    key: 'selectedSectionNameState',
    default: 'A'
})

export const receivedClassDetailsState = atom({
    key: 'receivedClassDetailsState',
    default : {}
})

export const receivedStudentListState = atom({
    key: 'receivedStudentListState',
    default : []
})

export const studentwiseDailyReportState = atom({
    key: 'studentwiseDailyReportState',
    default : []
})

export const teacherSelectedState = atom({
    key: 'teacherSelectedState',
    default : []
})

export const teacherSelectedClassState = atom({
    key: 'teacherSelectedClassState',
    default : ""
})

export const teacherSelectedSectionState = atom({
    key: 'teacherSelectedSectionState',
    default : ""
})

export const teacherSelectedDateState = atom({
    key: 'teacherSelectedDateState',
    default : new Date()
})

export const teacherSelectedSubjectState = atom({
    key: 'teacherSelectedSubjectState',
    default : ""
})

export const teacherSelectedExamState = atom({
    key: 'teacherSelectedExamState',
    default : null
})



export const editModeState = atom({
    key: 'editModeState',
    default: false
})
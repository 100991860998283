import React from "react";
import { Radar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

import "./Css/OverallChart.css";

Chart.register(...registerables);

const OverallChart = ({ data }) => {
  function calculateAverageScores(data) {
    let totalClassScore = 0;
    let totalHomeworkScore = 0;
    let totalMoralScienceScore = 0;
    let totalSportsScore = 0;
    let totalCommunicationSkillsScore = 0;
    let countClassScore = 0;
    let countHomeworkScore = 0;
    let countMoralScienceScore = 0;
    let countSportsScore = 0;
    let countCommunicationSkillsScore = 0;

    data.forEach((item) => {
      let classScorePerDate = 0;
      let homeworkScorePerDate = 0;
      let moralScienceScorePerDate = 0;
      let sportsScorePerDate = 0;
      let communicationSkillsScorePerDate = 0;
      let countClassScorePerDate = 0;
      let countHomeworkScorePerDate = 0;
      let countMoralScienceScorePerDate = 0;
      let countSportsScorePerDate = 0;
      let countCommunicationSkillsScorePerDate = 0;

      item.report.forEach((report) => {
        if (
          report.report.classScore !== undefined &&
          report.report.classScore !== 0
        ) {
          classScorePerDate += report.report.classScore;
          countClassScorePerDate++;
        }
        if (
          report.report.homeworkScore !== undefined &&
          report.report.homeworkScore !== 0
        ) {
          homeworkScorePerDate += report.report.homeworkScore;
          countHomeworkScorePerDate++;
        }
      });

      item.additionalScoreAndRemark.forEach((remark) => {
        if (
          remark.moralScienceScore !== undefined &&
          remark.moralScienceScore !== 0
        ) {
          moralScienceScorePerDate += remark.moralScienceScore;
          countMoralScienceScorePerDate++;
        }
        if (remark.sportsScore !== undefined && remark.sportsScore !== 0) {
          sportsScorePerDate += remark.sportsScore;
          countSportsScorePerDate++;
        }
        if (
          remark.communicationSkillsScore !== undefined &&
          remark.communicationSkillsScore !== 0
        ) {
          communicationSkillsScorePerDate += remark.communicationSkillsScore;
          countCommunicationSkillsScorePerDate++;
        }
      });

      if (countClassScorePerDate > 0) {
        totalClassScore += classScorePerDate / countClassScorePerDate;
        countClassScore++;
      }
      if (countHomeworkScorePerDate > 0) {
        totalHomeworkScore += homeworkScorePerDate / countHomeworkScorePerDate;
        countHomeworkScore++;
      }
      if (countMoralScienceScorePerDate > 0) {
        totalMoralScienceScore +=
          moralScienceScorePerDate / countMoralScienceScorePerDate;
        countMoralScienceScore++;
      }
      if (countSportsScorePerDate > 0) {
        totalSportsScore += sportsScorePerDate / countSportsScorePerDate;
        countSportsScore++;
      }
      if (countCommunicationSkillsScorePerDate > 0) {
        totalCommunicationSkillsScore +=
          communicationSkillsScorePerDate /
          countCommunicationSkillsScorePerDate;
        countCommunicationSkillsScore++;
      }
    });

    const averageClassScore =
      countClassScore > 0 ? totalClassScore / countClassScore : 0;
    const averageHomeworkScore =
      countHomeworkScore > 0 ? totalHomeworkScore / countHomeworkScore : 0;
    const averageMoralScienceScore =
      countMoralScienceScore > 0
        ? totalMoralScienceScore / countMoralScienceScore
        : 0;
    const averageSportsScore =
      countSportsScore > 0 ? totalSportsScore / countSportsScore : 0;
    const averageCommunicationSkillsScore =
      countCommunicationSkillsScore > 0
        ? totalCommunicationSkillsScore / countCommunicationSkillsScore
        : 0;

    return {
      averageClassScore,
      averageHomeworkScore,
      averageMoralScienceScore,
      averageSportsScore,
      averageCommunicationSkillsScore,
    };
  }

  const averages = calculateAverageScores(data);
  console.log(averages);
  if (!data || !data[0] || !data[0].report) {
    return null; // or some fallback UI
  }

  console.log(data);
  if (!data || !data[0] || !data[0].report) {
    return null; // or some fallback UI
  }

  const chartData = {
    labels: ["Class", "Hom..", "Moral..", "Spo..", "Comm.."],
    datasets: [
      {
        label: "Average Scores",
        data: [
          averages.averageClassScore,
          averages.averageHomeworkScore,
          averages.averageMoralScienceScore,
          averages.averageSportsScore,
          averages.averageCommunicationSkillsScore,
        ],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        pointRadius: 5,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  const options = {
    scales: {
      r: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Radar data={chartData} options={options} />
    </div>
  );
};

export default OverallChart;

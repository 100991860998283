import React from 'react'

import AddClass from './AddClass'
import AddStudent from './AddStudent'

const InputData = () => {
    return (
        <>
           <AddClass />
           <AddStudent /> 
        </>
    )
}

export default InputData

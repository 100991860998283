import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  List,
  ListItem,
  Button,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Select,
  Collapse,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/system";
import baseUrl from "../Configs";
import { useRecoilValue } from "recoil";

import {
  teacherSelectedClassState,
  teacherSelectedSubjectState,
  teacherSelectedSectionState,
} from "../Atoms";
import Header from "../Header";

const IndentedList = styled(List)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const Topic = ({ topic, handleStatusChange }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={topic.title} secondary={topic.description} />
        <ListItemSecondaryAction>
          <Select
            value={topic.status}
            onChange={(e) => handleStatusChange(topic._id, e.target.value)}
          >
            <MenuItem value="upcoming">Upcoming</MenuItem>
            <MenuItem value="in-progress">In Progress</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
          </Select>
          <IconButton edge="end">
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <IndentedList>
          {topic.subtopics.map((subtopic) => (
            <Topic
              key={subtopic._id}
              topic={subtopic}
              handleStatusChange={handleStatusChange}
            />
          ))}
        </IndentedList>
      </Collapse>
    </div>
  );
};

const LiveSyllabus = () => {
  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);
  const [isSyllabusFound, setIsSyllabusFound] = useState(false);
  const [syllabus, setSyllabus] = useState({ topics: [] });
  const [originalStatusSet, setOriginalStatusSet] = useState(new Map()); // [topicId, status]
  const [changedStatuses, setChangedStatuses] = useState({});

  let updateLiveSyllabus = `${baseUrl}/syllabusroute/updatelivestatus`;

  const handleStatusChange = (topicId, newStatus) => {
    // Find the topic in the syllabus
    const findAndChangeStatus = (topics) => {
      for (let i = 0; i < topics.length; i++) {
        if (topics[i]._id === topicId) {
          if (topics[i].originalStatus !== newStatus) {
            setChangedStatuses((prev) => ({ ...prev, [topicId]: newStatus }));
          } else {
            setChangedStatuses((prev) => {
              const { [topicId]: _, ...rest } = prev;
              return rest;
            });
          }
          // Directly update the topics array
          topics[i] = { ...topics[i], status: newStatus };
          return topics;
        }
        if (topics[i].subtopics) {
          const newSubtopics = findAndChangeStatus(topics[i].subtopics);
          if (newSubtopics) {
            // Directly update the topics array
            topics[i] = { ...topics[i], subtopics: newSubtopics };
            return topics;
          }
        }
      }
      return null;
    };

    const newTopics = findAndChangeStatus(syllabus.topics);
    if (newTopics) {
      setSyllabus({ topics: newTopics });
    } else {
      console.error(`No topic found with id ${topicId}`);
    }
  };

  const getChangedStatuses = (originalStatusSet, changedStatuses) => {
    const changedStatusesArray = [];

    for (const [id, status] of Object.entries(changedStatuses)) {
      if (originalStatusSet.get(id) !== status) {
        changedStatusesArray.push({ id, status });
      }
    }

    return changedStatusesArray;
  };

  const handleSave = async () => {
    const trulyChangedStatuses = getChangedStatuses(
      originalStatusSet,
      changedStatuses
    );

    try {
      const response = await axios.post(
        updateLiveSyllabus,
        trulyChangedStatuses,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data) {
        console.log("Syllabus updated: ", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createOriginalStatusSet = (topics) => {
    const originalStatusSet = new Map();

    const addTopicsToSet = (topics) => {
      for (let i = 0; i < topics.length; i++) {
        originalStatusSet.set(topics[i]._id, topics[i].status);
        if (topics[i].subtopics) {
          addTopicsToSet(topics[i].subtopics);
        }
      }
    };

    addTopicsToSet(topics);
    return originalStatusSet;
  };

  useEffect(() => {
    if (
      teacherSelectedClass &&
      teacherSelectedSection &&
      teacherSelectedSubject
    ) {
      console.log(
        "Teacher selected class, section, and subject: ",
        teacherSelectedClass,
        teacherSelectedSection,
        teacherSelectedSubject
      );
      let getSyllabusUrl = `${baseUrl}/syllabusroute/getsyllabus?className=${teacherSelectedClass}&subject=${teacherSelectedSubject}&section=${teacherSelectedSection}`;
      const fetchSyllabus = async () => {
        try {
          const response = await axios.get(getSyllabusUrl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          if (response.data) {
            setIsSyllabusFound(true);
            console.log("Syllabus found: ", response.data);
            setSyllabus(response.data);
            setOriginalStatusSet(createOriginalStatusSet(response.data.topics));
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchSyllabus();
    }
  }, [teacherSelectedClass, teacherSelectedSection, teacherSelectedSubject]);
  return (
    <div>
      <Header />
      <h1>{syllabus.subject}</h1>
      <List>
        {syllabus.topics.map((topic) => (
          <Topic
            key={topic._id}
            topic={topic}
            handleStatusChange={handleStatusChange}
          />
        ))}
      </List>
      <Button variant="contained" color="primary" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
};

export default LiveSyllabus;

import React, { useState, useEffect, useMemo } from "react";

import ClassSelector from "./ClassSelector";
import EditModeSwitch from "./EditModeSwitch";

import baseUrl from "./Configs";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
  teacherSelectedDateState,
  teacherSelectedSubjectState,
} from "./Atoms";

import "./Css/Header.css";

const Header = () => {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);

  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [selectedSubject, setSelectedSubject] = useRecoilState(
    teacherSelectedSubjectState
  );
  const [selectedDate, setTeacherSelectedDate] = useRecoilState(
    teacherSelectedDateState
  );

  const getClassesUrl = `${baseUrl}/classroute/getavailableclasses`;
  const getStudentsUrl = `${baseUrl}/classroute/getstudentlist?className=${selectedClass}&section=${selectedSection}`;

  const handleDateChange = (e) => {
    setTeacherSelectedDate(new Date(e));
    console.log(selectedDate);
  };
  const fetchClasses = async () => {
    const response = await fetch(getClassesUrl, optionsForGet);
    const data = await response.json();
    setClasses(groupClasses(data));
  };

  const fetchStudents = async () => {
    const response = await fetch(getStudentsUrl, optionsForGet);
    const data = await response.json();
    setStudents(data);
  };

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const groupClasses = (classes) => {
    return classes.reduce((groupedClasses, cls) => {
      // Find the class in the grouped classes
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      // If the class is not yet in the grouped classes, add it
      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        // Otherwise, add the section and subjects to the class in the grouped classes
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  useEffect(() => {
    fetchClasses();
    // if (selectedClass && selectedSection) {
    //   fetchStudents();
    // }
  }, []);

  let selectedClassObj = classes.find((cls) => cls.className === selectedClass);
  let selectedSectionObj =
    selectedClassObj &&
    selectedClassObj.sectionsAndSubjects.find(
      (sAs) => sAs.section == selectedSection
    );

  return (
    <div className="header-container">
      <div className="header-logo">
        <h3>SpellStudy</h3>
      </div>
      <div className="header-selector">
        <FormControl fullWidth>
          <InputLabel id="class-select-label">Select Class</InputLabel>
          <Select
            labelId="class-select-label"
            label="Select Class"
            id="class-select"
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value)}
          >
            {classes.map((cls, index) => (
              <MenuItem key={index} value={cls.className}>
                {cls.className}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="section-select-label">Select Section</InputLabel>
          <Select
            labelId="section-select-label"
            label="Select Section"
            id="section-select"
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.target.value)}
          >
            {selectedClassObj &&
              selectedClassObj.sectionsAndSubjects.map((sAs, index) => (
                <MenuItem key={index} value={sAs.section}>
                  {sAs.section}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="subject-select-label">Select Subject</InputLabel>
          <Select
            labelId="subject-select-label"
            label="Select Subject"
            id="subject-select"
            value={selectedSubject}
            onChange={(e) => setSelectedSubject(e.target.value)}
          >
            {selectedSectionObj &&
              selectedSectionObj.subjects.map((subject, index) => (
                <MenuItem key={index} value={subject.subjectName}>
                  {subject.subjectName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Date"
            inputFormat="dd/MM/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default Header;

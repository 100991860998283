import React, { useState, useEffect } from 'react';
import { List, ListItem, Select, MenuItem, Button } from '@mui/material';
import baseUrl from './Configs';

const MasterUserTasks = () => {
  const [pendingSchools, setPendingSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [status, setStatus] = useState('');

  let getAllSchoolsUrl = `${baseUrl}/schoolroute/getallschools`;
  let getPendingSchoolsUrl = `${baseUrl}/schoolroute/getpendingschools`;
  let updateSchoolStatusUrl = `${baseUrl}/schoolroute/updateschoolstatus`;

  useEffect(() => {
    getPendingSchools();
  }, []);

  const getPendingSchools = async () => {
    const response = await fetch(getPendingSchoolsUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (response.ok) {
      const data = await response.json();
      setPendingSchools(data);
    } else {
      console.error('Failed to fetch pending schools');
    }
  };

  const updateSchoolStatus = async () => {
    const response = await fetch(updateSchoolStatusUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ schoolId: selectedSchool, status })
    });

    if (response.ok) {
      getPendingSchools();
    } else {
      console.error('Failed to update school status');
    }
  };

  return (
    <div>
      <h1>SuperUserTasks</h1>
      <List>
        {pendingSchools.map(school => (
          <ListItem key={school.schoolId}>
            {school.schoolName}
            <Select value={status} onChange={(e) => { setSelectedSchool(school.schoolId); setStatus(e.target.value); }}>
              <MenuItem value={'Pending'}>Pending</MenuItem>
              <MenuItem value={'Active'}>Active</MenuItem>
              <MenuItem value={'Disabled'}>Disabled</MenuItem>
            </Select>
            <Button onClick={updateSchoolStatus}>Update Status</Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MasterUserTasks;
import React, { useEffect, useState } from "react";
import baseUrl from "../Configs";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DayRating from "./DayRating";
import AdditionalRating from "./AdditonalRating";
import SubmitDayRating from "./SubmitDayRating";
import ExamRating from "./ExamRating";
import AddHomework from "./AddHomework";
import CreateNewExam from "./CreateNewExam";
import EditExistingExam from "./EditExistingExam";
import { useRecoilState } from "recoil";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import {
  teacherSelectedClassState,
  teacherSelectedSectionState,
  teacherSelectedExamState,
} from "../Atoms";

import "../Css/Basics.css";
import AddAttendance from "../Teacher-app/AddAttendance";
import Header from "../Header";
import "./Css/TeacherHome.css";
import AddNotification from "./AddNotification";

const TeacherHome = () => {
  const [examList, setExamList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [selectedExam, setSelectedExam] = useRecoilState(
    teacherSelectedExamState
  );

  const handleButtonClick = (option) => {
    setSelectedOption(option);
  };

  const handleBackClick = () => {
    setSelectedOption(null);
  };

  let getExamsUrl = `${baseUrl}/classroute/getexamsbyclassandsection?classname=${selectedClass}&section=${selectedSection}`;

  let options = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getExams = async () => {
    try {
      const response = await fetch(getExamsUrl, options);
      const data = await response.json();
      setExamList(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedOption === "Add Exam Rating") {
      getExams();
    }
  }, [selectedOption, getExamsUrl]);

  const handleExamChange = (event) => {
    setSelectedExam(event.target.value);
  };

  return (
    <div>
      <Header />
      <div className="teacherhome-container">
        {(() => {
          switch (selectedOption) {
            case "Take Attendance":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Attendance</span>
                  </div>
                  <AddAttendance onSubmit={() => setSelectedOption(null)} />
                </>
              );
            case "Add Daily Rating":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Daily Rating</span>
                  </div>
                  <DayRating />
                  <SubmitDayRating onSubmit={() => setSelectedOption(null)} />
                </>
              );
            case "Add Additional Rating":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Additional Rating</span>
                  </div>
                  <AdditionalRating onSubmit={() => setSelectedOption(null)} />
                </>
              );
            case "Add Exam Rating":
              return (
                <>
                  <div className="teacherhome-sectionHeader">
                    <div className="teacher-home_secondary-header blue-button">
                      <button
                        className="teacher-home_back-button"
                        onClick={handleBackClick}
                      >
                        <ArrowBackIosIcon /> Back
                      </button>
                      <span>Add Exam Rating</span>
                    </div>
                    <FormControl fullWidth>
                      <InputLabel id="exam-select-label">
                        Select Exam
                      </InputLabel>
                      <Select
                        labelId="exam-select-label"
                        id="exam-select"
                        value={selectedExam}
                        onChange={handleExamChange}
                      >
                        {examList.map((exam, index) => (
                          <MenuItem key={index} value={exam.examName}>
                            {exam.examName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <ExamRating onSubmit={() => setSelectedOption(null)} />
                </>
              );

            case "Create New Exam":
              return (
                <>
                  <button onClick={handleBackClick}>Back</button>
                  <CreateNewExam onSubmit={() => setSelectedOption(null)} />
                </>
              );
            case "Edit Existing Exam":
              return (
                <>
                  <button onClick={handleBackClick}>Back</button>
                  <EditExistingExam onSubmit={() => setSelectedOption(null)} />
                </>
              );

            case "Add Homework":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Homework</span>
                  </div>
                  <AddHomework onSubmit={() => setSelectedOption(null)} />
                </>
              );
            case "Add Notification":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Notification</span>
                  </div>
                  <AddNotification onSubmit={() => setSelectedOption(null)} />
                </>
              );

            default:
              return (
                <div className="teacherhome-options-container">
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Take Attendance")}
                  >
                    Take Attendance
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Daily Rating")}
                  >
                    Add Daily Rating
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Additional Rating")}
                  >
                    Add Additional Rating
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Exam Rating")}
                  >
                    Add Exam Rating
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Create New Exam")}
                  >
                    Create New Exam
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Edit Existing Exam")}
                  >
                    Edit Existing Exam
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Homework")}
                  >
                    Add Homework
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Notification")}
                  >
                    Add Notification
                  </div>
                </div>
              );
          }
        })()}
      </div>
    </div>
  );
};

export default TeacherHome;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import AddClass from "./AddClass";
// import CreateNewExam from "./CreateNewExam";
import AddStudent from "./AddStudent";
import EditExistingExam from "./EditExistingExam";
import AddNewTeacher from "./AddNewTeacher";
import EditExistingClass from "./EditExistingClass";
import RegisterHeader from "./RegisterHeader";
import AddNewSyllabus from "./AddNewSyllabus";

import "./Css/AdminTasks.css";
import AddNewStaff from "./AddNewStaff";

const AdminTasks = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = (option) => {
    setSelectedOption(option);
    if (option === "Add New Syllabus") {
      navigate("/add-new-syllabus");
    }
  };

  const handleBackClick = () => {
    setSelectedOption(null);
  };

  return (
    <div>
      <div className="admint-tasks_header">
        <RegisterHeader />
      </div>
      {(() => {
        switch (selectedOption) {
          case "Create New Class":
            return (
              <>
                <button onClick={handleBackClick}>Back</button>
                <AddClass onSubmit={() => setSelectedOption(null)} />
              </>
            );
          case "Edit Existing Class":
            return (
              <>
                <button onClick={handleBackClick}>Back</button>
                <EditExistingClass onSubmit={() => setSelectedOption(null)} />
              </>
            );
          // case "Create New Exam":
          //   return (
          //     <>
          //       <button onClick={handleBackClick}>Back</button>
          //       <CreateNewExam onSubmit={() => setSelectedOption(null)} />
          //     </>
          //   );
          case "Edit Existing Exam":
            return (
              <>
                <button onClick={handleBackClick}>Back</button>
                <EditExistingExam onSubmit={() => setSelectedOption(null)} />
              </>
            );
          case "Add New Student":
            return (
              <>
                <button onClick={handleBackClick}>Back</button>
                <AddStudent onSubmit={() => setSelectedOption(null)} />
              </>
            );
          case "Add New Staff":
            return (
              <>
                <button onClick={handleBackClick}>Back</button>
                <AddNewStaff onSubmit={() => setSelectedOption(null)} />
              </>
            );
          case "Add New Syllabus":
            return (
              <>
                <button onClick={handleBackClick}>Back</button>
                <AddNewSyllabus onSubmit={() => setSelectedOption(null)} />
              </>
            );
          default:
            return (
              <div className="options-container">
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Create New Class")}
                >
                  Create New Class
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Edit Existing Class")}
                >
                  Edit Existing Class
                </div>
                {/* <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Create New Exam")}
                >
                  Create New Exam
                </div> */}
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Edit Existing Exam")}
                >
                  Edit Existing Exam
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Add New Student")}
                >
                  Add New Student
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Add New Staff")}
                >
                  Add New Staff
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Add New Syllabus")}
                >
                  Add New Syllabus
                </div>
              </div>
            );
        }
      })()}
    </div>
  );
};

export default AdminTasks;

import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import baseUrl from "./Configs";
import "./Css/ShowRemarks.css";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
} from "./Atoms";

const ShowRemarks = ({ studentId }) => {
  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [remarks, setRemarks] = useState([]);
  const [newRemark, setNewRemark] = useState("");

  let options = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  let addRemarksUrl = `${baseUrl}/studentroute/addgeneralremark`;

  const getRemarks = async () => {
    let getRemarksUrl = `${baseUrl}/studentroute/getgeneralremarks?className=${selectedClass}&section=${selectedSection}&si=${studentId}`;
    console.log(getRemarksUrl);
    try {
      const response = await fetch(getRemarksUrl, options);
      const data = await response.json();
      setRemarks(data);
      console.log("getRemarks: ", data);
    } catch (error) {
      console.log(error);
    }
  };

  const addRemark = async () => {
    const remarkData = {
      className: selectedClass,
      section: selectedSection,
      rollNumber: studentId,
      remark: newRemark,
    };

    const response = await fetch(addRemarksUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(remarkData),
    });

    if (response.ok) {
      setNewRemark("");
      getRemarks();
    } else {
      console.error("Error adding remark");
    }
  };

  useEffect(() => {
    getRemarks();
  }, []);

  return (
    <div className="show-remarks-container">
      {remarks.map((remark) => (
        <div key={remark._id}>
          <p>{remark.remark}</p>
          <p>By: {remark.author && remark.author.personname}</p>
          <p>Sent: {new Date(remark.timestamp).toLocaleString()}</p>
        </div>
      ))}
      <input
        type="text"
        value={newRemark}
        onChange={(e) => setNewRemark(e.target.value)}
        placeholder="Add a remark"
      />
      <button onClick={addRemark}>Send</button>
    </div>
  );
};

export default ShowRemarks;

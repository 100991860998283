import React from "react";

import TeacherHeader from "./TeacherHeader";
// import Header from '../Header'
import DayRating from "./DayRating";
import SubmitDayRating from "./SubmitDayRating";

const Class = () => {
  return (
    <div className="class">
      <TeacherHeader />
      <DayRating />
      <SubmitDayRating />
    </div>
  );
};

export default Class;

import React from "react";
import { Link } from "react-router-dom";

import "./Css/RegisterHeader.css";
import Logout from "./LogOut";

const RegisterHeader = () => {
  return (
    <div className="register-header">
      <h1>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          SpellStudy
        </Link>
      </h1>
      <Logout />
    </div>
  );
};

export default RegisterHeader;

import React from "react";
import { useNavigate } from "react-router-dom";
import RegisterHeader from "./RegisterHeader";

import "./Css/SuperUserHome.css";

const SuperUserHome = () => {
  const navigate = useNavigate();

  const navigateToAdminTasks = () => {
    navigate("/admintasks");
  };

  const navigateToTeacherHome = () => {
    navigate("/teacherHome");
  };

  const navigateToClasswiseDetails = () => {
    navigate("/t");
  };
  const navigateToFeeStatus = () => {
    navigate("/feeStatus");
  };
  const navigateToSalaryStatus = () => {
    navigate("/salaryStatus");
  };

  return (
    <div>
      <RegisterHeader />
      <div className="superuserhome-content">
        <div className="superuserhome-card" onClick={navigateToAdminTasks}>
          Admin Tasks
        </div>
        <div className="superuserhome-card" onClick={navigateToTeacherHome}>
          Teacher Tasks
        </div>
        <div
          className="superuserhome-card"
          onClick={navigateToClasswiseDetails}
        >
          Class wise students details
        </div>
        <div className="superuserhome-card">Teacher Bio</div>
        <div className="superuserhome-card" onClick={navigateToFeeStatus}>
          Fee Details
        </div>
        <div className="superuserhome-card" onClick={navigateToSalaryStatus}>
          Salary Details
        </div>
        <div className="superuserhome-card">Class wise status</div>
        <div className="superuserhome-card">School Overall Status</div>
      </div>
    </div>
  );
};

export default SuperUserHome;

import React, { useState, useEffect } from "react";
import baseUrl from "./Configs";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import "./Css/CreateNewExam.css";
import "./Css/Basics.css";

const EditExistingExam = (props) => {
  const [examName, setExamName] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [subjects, setSubjects] = useState([{ subjectName: "", date: "" }]);
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);

  let editExamUrl = `${baseUrl}/classroute/addsubjectsinexam`;
  const getClassesUrl = `${baseUrl}/classroute/getavailableclasses`;
  let getAllExamsByClassUrl = (classname, section) =>
    `${baseUrl}/classroute/getexamsbyclassandsection?classname=${classname}&section=${section}`;

  let newExamDetails = {
    examName: selectedExam,
    className: selectedClass,
    section: selectedSection,
    subjects: subjects,
  };

  const handleInputChange = (event) => {
    setExamName(event.target.value);
  };

  const handleSubmit = async (event) => {
    fetch(editExamUrl, optionsForEditExam)
      .then((data) => data.text())
      .then((textData) => console.log(textData))
      .then(props.onSubmit())
      .catch((e) => console.log(e));
  };

  const handleTeacherChange = (index, event) => {
    const values = [...subjects];
    values[index] = { ...values[index], teacher: event.target.value };
    setSubjects(values);
    console.log("teacher", event.target.value);
  };

  const handleSubjectChange = (index, event) => {
    const values = [...subjects];
    values[index] = { ...values[index], subjectName: event.target.value };
    console.log("values", values);
    setSubjects(values);
  };

  let optionsForEditExam = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(newExamDetails),
  };

  let optionsForClass = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
    getAllExamsByClass(selectedClass, event.target.value);
  };

  const handleExamChange = (event) => {
    setSelectedExam(event.target.value);
  };

  const handleDateChange = (index, date) => {
    const newSubjects = [...subjects];
    newSubjects[index].date = date;
    setSubjects(newSubjects);
  };

  const getAllExamsByClass = async (classname, section) => {
    await fetch(
      getAllExamsByClassUrl(classname, section),
      optionsForGetExamsByClass
    )
      .then((response) => response.json())
      .then((data) => setExams(data))
      .then((data) => console.log(data))
      .catch((error) => console.error("Failed to fetch classes:", error));
  };

  const optionsForGetExamsByClass = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const groupClasses = (classes) => {
    return classes.reduce((groupedClasses, cls) => {
      // Find the class in the grouped classes
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      // If the class is not yet in the grouped classes, add it
      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        // Otherwise, add the section and subjects to the class in the grouped classes
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  const addMore = () => {
    setSubjects([...subjects, { subjectName: "", date: "" }]);
    console.log("subjects", subjects);
  };

  useEffect(() => {
    fetch(getClassesUrl, optionsForClass)
      .then((response) => response.json())
      .then((data) => setClasses(groupClasses(data)))
      .catch((error) => console.error("Failed to fetch classes:", error));
  }, []);

  return (
    <div className="createNewExamSection">
      <div className="inputSection">
        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="class-select-label">Select Class</InputLabel>
            <Select
              labelId="class-select-label"
              id="class-select"
              value={selectedClass}
              onChange={handleClassChange}
            >
              {classes.map((cls, index) => (
                <MenuItem key={index} value={cls.className}>
                  {cls.className}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="section-select-label">Section</InputLabel>
            <Select
              labelId="section-select-label"
              id="section-select"
              value={selectedSection}
              onChange={handleSectionChange}
            >
              {selectedClass &&
                classes
                  .filter((cls) => cls.className === selectedClass)[0]
                  .sectionsAndSubjects.map((sAs, index) => (
                    <MenuItem key={index} value={sAs.section}>
                      {sAs.section}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="inputSection">
        <FormControl fullWidth>
          <InputLabel id="exam-select-label">Select Exam</InputLabel>
          <Select
            labelId="exam-select-label"
            id="exam-select"
            value={selectedExam}
            onChange={handleExamChange}
          >
            {exams.map((exam, index) => (
              <MenuItem key={index} value={exam.examName}>
                {exam.examName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        {subjects.map((subject, index) => (
          <div>
            <Box key={index} className="inputSection">
              <FormControl fullWidth>
                <InputLabel id="subject-select-label">Subject</InputLabel>
                <Select
                  labelId="subject-select-label"
                  id="subject-select"
                  value={subject.subjectName}
                  onChange={(e) => handleSubjectChange(index, e)}
                >
                  {selectedClass &&
                    selectedSection &&
                    classes
                      .filter((cls) => cls.className == selectedClass)[0]
                      .sectionsAndSubjects.filter(
                        (sAs) => sAs.section == selectedSection
                      )[0]
                      .subjects.map((subject, index) => (
                        <MenuItem key={index} value={subject.subjectName}>
                          {subject.subjectName}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date"
                    value={subject.date}
                    onChange={(date) => handleDateChange(index, date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
          </div>
        ))}
        <div className="orange-button" onClick={addMore}>
          Add More Subject
        </div>
      </div>
      <div className="addButton" onClick={() => handleSubmit()}>
        <p>Create Exam</p>
      </div>
    </div>
  );
};

export default EditExistingExam;

import React from "react";
import baseUrl from "../Configs";

import "./Css/DayRating.css";

import { useRecoilValue } from "recoil";
import {
  studentwiseDailyReportState,
  teacherSelectedClassState,
  teacherSelectedDateState,
  teacherSelectedSubjectState,
  teacherSelectedSectionState,
} from "../Atoms";

const SubmitDayRating = ({ onSubmit }) => {
  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedDate = useRecoilValue(teacherSelectedDateState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);
  const studentwiseDailyReport = useRecoilValue(studentwiseDailyReportState);

  let updateDailyReportUrl = `${baseUrl}/studentroute/updatedailyreport`;

  const updateDailyReport = async () => {
    console.log(" date: ", teacherSelectedDate);
    await fetch(updateDailyReportUrl, options)
      // .then((data) => data.json())
      .then((jsonData) => console.log(jsonData))
      .catch((e) => console.log(e));
  };

  let classwiseReport = {
    className: teacherSelectedClass,
    section: teacherSelectedSection,
    date: teacherSelectedDate,
    subject: teacherSelectedSubject,
    report: studentwiseDailyReport,
  };
  const handleSubmit = () => {
    if (teacherSelectedSubject === "") {
      window.alert("Please select a subject");
      return;
    }

    if (studentwiseDailyReport.length === 0) {
      window.alert("Please rate the students or click back to go back");
      return;
    }

    updateDailyReport();
    console.log(classwiseReport);
    onSubmit();
  };
  let options = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(classwiseReport),
  };

  return (
    <button className="submit-day-rating" onClick={() => handleSubmit()}>
      SubmitDayRating
    </button>
  );
};

export default SubmitDayRating;

import React, { useEffect, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  Button,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Collapse,
  Box,
} from "@mui/material";
import baseUrl from "./Configs";
import { useRecoilState } from "recoil";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
} from "./Atoms";
import Header from "./Header";
import "./Css/StudentFee.css";

const StudentFeeStatus = () => {
  const [students, setStudents] = useState({});
  const [studentFeeStatus, setStudentFeeStatus] = useState({});
  const [selectedStudentId, setSelectedStudentId] = useState();
  const [feeType, setFeeType] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [paidBy, setPaidBy] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [paymentMode, setPaymentMode] = useState("");

  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [selectedStudent, setSelectedStudent] = useState(null);

  const [open, setOpen] = useState(false);
  const [monthIndex, setMonthIndex] = useState(4);

  const getStudentFeeStatusUrl = (studentId) =>
    `${baseUrl}/feeroute/getstudentfeestatus?sti=${studentId}`;
  const getStudentsUrl = `${baseUrl}/classroute/getpartialstudentlist?classname=${selectedClass}&section=${selectedSection}`;
  const payFeeUrl = `${baseUrl}/feeroute/newFeeStatus`;
  const getClassFeeStatusUrl = `${baseUrl}/feeroute/getfeestatusofeachstudentinclass?className=${selectedClass}&section=${selectedSection}&monthIndex=${monthIndex}`;
  const getClassFeeStatus = async () => {
    await fetch(getClassFeeStatusUrl, optionsForGet)
      .then((data) => data.json())
      .then((jsonData) => console.log(jsonData))
      .catch((e) => console.log(e));
  };

  const fetchStudents = async () => {
    const response = await fetch(getStudentsUrl, optionsForGet);
    const data = await response.json();
    setStudents(data);
    // console.log(data);
  };

  const fetchStudentFeeStatus = async (studentId) => {
    const response = await fetch(
      getStudentFeeStatusUrl(studentId),
      optionsForGet
    );
    const data = await response.json();
    setStudentFeeStatus(data);
  };

  const handleRowClick = (student) => {
    console.log("Hello", student);
    if (!open) {
      fetchStudentFeeStatus(student.id);
    }
    setOpen(!open);
    setSelectedStudent(student.id);
  };

  const handleFeeTypeChange = (event) => {
    setFeeType(event.target.value);
  };

  const handleAmountChange = (event) => {
    setPaidAmount(event.target.value);
  };

  const handlePaidByChange = (event) => {
    setPaidBy(event.target.value);
  };

  const handlePayFee = async () => {
    const response = await fetch(payFeeUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        studentId: selectedStudent,
        amount: paidAmount,
        type: feeType,
        paidBy: paidBy,
        receiptNumber: receiptNumber,
        paymentMode: paymentMode,
      }),
    });
    const data = await response.json();
    console.log(data);
  };

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getMonthFromIndex = (index) => {
    switch (index) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        return "Invalid Month";
    }
  };

  const getLastFeeDetails = (feeStatus, feeType) => {
    let lastFeeDetail = 0;
    let lastFeeMonth = "";
    for (let i = feeStatus.length - 1; i >= 0; i--) {
      const feeDetail = feeStatus[i].feeDetails.find(
        (fee) => fee.feeType === feeType
      );
      if (feeDetail) {
        lastFeeDetail = feeDetail.amountPaid;
        lastFeeMonth = feeStatus[i]?.month;
        console.log("hi: ", feeStatus[i]?.month);
        break;
      }
    }
    return {
      lastFeeDetail: lastFeeDetail,
      month: getMonthFromIndex(new Date(lastFeeMonth).getMonth()),
    };
  };

  const getLastFeeMonth = (feeStatus) => {
    const lastFeeStatus = feeStatus?.slice(-1)[0];
    const lastFeeMonth = getMonthFromIndex(
      new Date(lastFeeStatus?.month).getMonth()
    );
    return lastFeeMonth;
  };

  useEffect(() => {
    fetchStudents();
  }, [selectedSection]);

  return (
    <div className="student-table-2_parent">
      <Header />
      <TableContainer className="tableContainer" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Roll Number</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students &&
              students.studentList &&
              students.studentList.map((student, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    onClick={() => handleRowClick(student)}
                    className={
                      selectedStudent === student.id && open ? "sticky-row" : ""
                    }
                  >
                    <TableCell>{student.rollNumber}</TableCell>
                    <TableCell>{student.name}</TableCell>
                    {/* {console.log(student)} */}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={open && selectedStudent === student.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>Hello</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={6}
                                >
                                  <Box margin={1}>
                                    <Table size="small" aria-label="purchases">
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>
                                            <div className="studentFeeChartContainer">
                                              {studentFeeStatus && (
                                                <div className="feeDetailsCard">
                                                  <h2>Student Fee Details</h2>

                                                  <div>
                                                    <p>
                                                      Tuition Fee Per Month :{" "}
                                                      {
                                                        studentFeeStatus
                                                          ?.feeDetails
                                                          ?.tuitionFeePerMonth
                                                      }
                                                    </p>
                                                  </div>

                                                  <div>
                                                    <p>
                                                      Admission Fee :{" "}
                                                      {
                                                        studentFeeStatus
                                                          ?.feeDetails
                                                          ?.admissionFee
                                                      }
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p>
                                                      Examination Fee :{" "}
                                                      {
                                                        studentFeeStatus
                                                          ?.feeDetails
                                                          ?.examinationFee
                                                      }
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p>
                                                      Transport Fee Per Month :{" "}
                                                      {
                                                        studentFeeStatus
                                                          ?.feeDetails
                                                          ?.transportFeePerMonth
                                                      }
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p>Other Fee</p>
                                                    {studentFeeStatus?.feeDetails?.otherFee?.map(
                                                      (fee, index) => (
                                                        <div key={index}>
                                                          <p>
                                                            Amount:{" "}
                                                            {fee?.amount}
                                                          </p>
                                                          <p>
                                                            Description:{" "}
                                                            {fee?.description ||
                                                              "N/A"}
                                                          </p>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                              <div className="feeDetailsCard">
                                                {studentFeeStatus?.feeStatus && (
                                                  <div>
                                                    <h2>Pay Fee</h2>
                                                    <p>
                                                      <span>
                                                        Last paid tuition fee :{" "}
                                                      </span>
                                                      <span>
                                                        {
                                                          getLastFeeDetails(
                                                            studentFeeStatus.feeStatus,
                                                            "tuitionFee"
                                                          ).lastFeeDetail
                                                        }
                                                      </span>
                                                      <span>
                                                        {" "}
                                                        for month{" "}
                                                        {
                                                          getLastFeeDetails(
                                                            studentFeeStatus.feeStatus,
                                                            "tuitionFee"
                                                          ).month
                                                        }
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <span>
                                                        Last paid transport fee
                                                        :{" "}
                                                      </span>
                                                      <span>
                                                        {
                                                          getLastFeeDetails(
                                                            studentFeeStatus.feeStatus,
                                                            "transportFee"
                                                          ).lastFeeDetail
                                                        }
                                                      </span>
                                                      <span>
                                                        {" "}
                                                        for month{" "}
                                                        {
                                                          getLastFeeDetails(
                                                            studentFeeStatus.feeStatus,
                                                            "transportFee"
                                                          ).month
                                                        }
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <span>
                                                        <FormControl id="fee-type-select">
                                                          <InputLabel id="fee-type-label">
                                                            Select Fee Type
                                                          </InputLabel>
                                                          <Select
                                                            labelId="fee-type-label"
                                                            value={feeType}
                                                            onChange={
                                                              handleFeeTypeChange
                                                            }
                                                          >
                                                            <MenuItem
                                                              value={
                                                                "tuitionFee"
                                                              }
                                                            >
                                                              Tuition Fee
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={
                                                                "transportFee"
                                                              }
                                                            >
                                                              Transport Fee
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={
                                                                "admissionFee"
                                                              }
                                                            >
                                                              Admission Fee
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={"examFee"}
                                                            >
                                                              Exam Fee
                                                            </MenuItem>
                                                          </Select>
                                                        </FormControl>
                                                      </span>
                                                      <span>
                                                        <TextField
                                                          label="Amount"
                                                          value={paidAmount}
                                                          onChange={
                                                            handleAmountChange
                                                          }
                                                        />
                                                        <TextField
                                                          label="Paid by"
                                                          value={paidBy}
                                                          onChange={
                                                            handlePaidByChange
                                                          }
                                                        />
                                                        <FormControl id="fee-type-select">
                                                          <InputLabel id="fee-type-label">
                                                            Select Payment Mode
                                                          </InputLabel>
                                                          <Select
                                                            labelId="fee-type-label"
                                                            value={paymentMode}
                                                            onChange={(e) =>
                                                              setPaymentMode(
                                                                e.target.value
                                                              )
                                                            }
                                                          >
                                                            <MenuItem
                                                              value={"Cash"}
                                                            >
                                                              Cash
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={"UPI"}
                                                            >
                                                              UPI
                                                            </MenuItem>
                                                            <MenuItem
                                                              value={"Onlline"}
                                                            >
                                                              Onlline
                                                            </MenuItem>
                                                          </Select>
                                                        </FormControl>
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <Button
                                                        onClick={handlePayFee}
                                                      >
                                                        Pay Fee
                                                      </Button>
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StudentFeeStatus;

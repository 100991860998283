import React from "react";
import { Link } from "react-router-dom";
import "./Css/ThankyouPage.css";

const ThankyouPage = () => {
  return (
    <div className="thankyou-page">
      <h1>Thank You!</h1>
      <p>Your registration form has been submitted successfully.</p>
      <p>We will get back to you shortly.</p>
      <Link to="/entrypage">Go back to login</Link>
    </div>
  );
};

export default ThankyouPage;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BounceLoader from "react-spinners/ClipLoader";
import baseUrl from "./Configs";

// import "./Css/AddStudent.css";

import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const AddStudent = ({ onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [rollNumber, setRollNumber] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [admissionNumber, setAdmissionNumber] = useState("");
  const [previousSchool, setPreviousSchool] = useState("");
  const [busService, setBusService] = useState("");
  const [busNumber, setBusNumber] = useState("");
  const [busStop, setBusStop] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [parentsInfo, setParentsInfo] = useState([
    {
      relation: "",
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      education: "",
      occupation: "",
    },
  ]);

  const navigate = useNavigate();

  const getClassesUrl = `${baseUrl}/classroute/getavailableclasses`;

  const optionsForClass = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    setSelectedSection(""); // Reset section when class changes
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleParentChange = (index, field, value) => {
    const updatedParents = [...parentsInfo];
    updatedParents[index][field] = value;
    setParentsInfo(updatedParents);
  };

  const addParent = () => {
    setParentsInfo([
      ...parentsInfo,
      {
        relation: "",
        name: "",
        email: "",
        phoneNumber: "",
        address: "",
        education: "",
        occupation: "",
      },
    ]);
  };

  const removeParent = (index) => {
    const updatedParents = parentsInfo.filter((_, i) => i !== index);
    setParentsInfo(updatedParents);
  };

  const StudentDetails = {
    name,
    className: selectedClass,
    section: selectedSection,
    rollNumber,
    address,
    parentsInfo,
    admissionNumber,
    previousSchool,
    busService,
    busNumber,
    busStop,
    gender,
    email,
    dateOfBirth,
    phoneNumber,
    username,
    password,
  };

  const addNewStudentUrl = `${baseUrl}/studentroute/addnewstudent`;

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(StudentDetails),
  };

  const addNewStudent = async () => {
    // Basic validation (optional but recommended)
    if (!name || !selectedClass || !selectedSection) {
      alert("Please fill in all required fields.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(addNewStudentUrl, options);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      console.log(jsonData);
      onSubmit();
    } catch (e) {
      console.error("Failed to add student:", e);
      alert("Failed to add student. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const groupClasses = (classes) => {
    return classes.reduce((groupedClasses, cls) => {
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(getClassesUrl, optionsForClass)
      .then((response) => response.json())
      .then((data) => {
        setClasses(groupClasses(data));
      })
      .catch((error) => {
        console.error("Failed to fetch classes:", error);
        alert("Failed to fetch classes. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Box className="addNewStudentSection" sx={{ p: 3 }}>
      {isLoading && (
        <Box
          className="loader"
          sx={{ display: "flex", justifyContent: "center", my: 4 }}
        >
          <BounceLoader color="#4A90E2" loading={isLoading} size={60} />
        </Box>
      )}
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Student Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="class-select-label">Class</InputLabel>
                <Select
                  labelId="class-select-label"
                  id="class-select"
                  value={selectedClass}
                  label="Class"
                  onChange={handleClassChange}
                >
                  {classes.map((cls, index) => (
                    <MenuItem key={index} value={cls.className}>
                      {`Class ${cls.className}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="section-select-label">Section</InputLabel>
                <Select
                  labelId="section-select-label"
                  id="section-select"
                  value={selectedSection}
                  label="Section"
                  onChange={handleSectionChange}
                  disabled={!selectedClass}
                >
                  {selectedClass &&
                    classes
                      .find((cls) => cls.className === selectedClass)
                      ?.sectionsAndSubjects.map((sAs, index) => (
                        <MenuItem key={index} value={sAs.section}>
                          {sAs.section}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Full Name"
                variant="filled"
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="gender-select-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  value={gender}
                  label="Gender"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Admission Number"
                variant="filled"
                fullWidth
                value={admissionNumber}
                onChange={(e) => setAdmissionNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Previous School"
                variant="filled"
                fullWidth
                value={previousSchool}
                onChange={(e) => setPreviousSchool(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Bus Service"
                variant="filled"
                fullWidth
                value={busService}
                onChange={(e) => setBusService(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Bus Number"
                variant="filled"
                fullWidth
                value={busNumber}
                onChange={(e) => setBusNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Bus Stop"
                variant="filled"
                fullWidth
                value={busStop}
                onChange={(e) => setBusStop(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of Birth"
                  value={dateOfBirth}
                  onChange={(newValue) => setDateOfBirth(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="filled" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                variant="filled"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="filled"
                fullWidth
                multiline
                rows={3}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Roll Number"
                variant="filled"
                fullWidth
                value={rollNumber}
                onChange={(e) => setRollNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                variant="filled"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Username"
                variant="filled"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                variant="filled"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* Guardians Section */}
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Guardians Information
            </Typography>
            {parentsInfo.map((parent, index) => (
              <Box
                key={index}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  p: 2,
                  mb: 2,
                  position: "relative",
                }}
              >
                <IconButton
                  aria-label="remove guardian"
                  onClick={() => removeParent(index)}
                  disabled={parentsInfo.length === 1}
                  sx={{ position: "absolute", top: 8, right: 8 }}
                >
                  <RemoveCircleOutlineIcon color="error" />
                </IconButton>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                      <InputLabel id={`relation-label-${index}`}>
                        Relation
                      </InputLabel>
                      <Select
                        labelId={`relation-label-${index}`}
                        id={`relation-select-${index}`}
                        value={parent.relation}
                        label="Relation"
                        onChange={(e) =>
                          handleParentChange(index, "relation", e.target.value)
                        }
                      >
                        <MenuItem value="father">Father</MenuItem>
                        <MenuItem value="mother">Mother</MenuItem>
                        <MenuItem value="guardian">Guardian</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Name"
                      variant="filled"
                      fullWidth
                      required
                      value={parent.name}
                      onChange={(e) =>
                        handleParentChange(index, "name", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      variant="filled"
                      fullWidth
                      value={parent.email}
                      onChange={(e) =>
                        handleParentChange(index, "email", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Phone Number"
                      variant="filled"
                      fullWidth
                      value={parent.phoneNumber}
                      onChange={(e) =>
                        handleParentChange(index, "phoneNumber", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Address"
                      variant="filled"
                      fullWidth
                      multiline
                      rows={2}
                      value={parent.address}
                      onChange={(e) =>
                        handleParentChange(index, "address", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Education"
                      variant="filled"
                      fullWidth
                      value={parent.education}
                      onChange={(e) =>
                        handleParentChange(index, "education", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Occupation"
                      variant="filled"
                      fullWidth
                      value={parent.occupation}
                      onChange={(e) =>
                        handleParentChange(index, "occupation", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              onClick={addParent}
            >
              Add Guardian
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Submit Button */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={addNewStudent}
          disabled={isLoading}
        >
          Add New Student
        </Button>
      </Box>
    </Box>
  );
};

export default AddStudent;

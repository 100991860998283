import React from 'react'

import './Css/Banner.css'

const Banner = () => {
  return (
    <div className='banner'>
      <span>
        Give your school the SpellStudy Experience
      </span>
    </div>
  )
}

export default Banner
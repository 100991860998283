// const localhost = "http://13.61.74.247";
// const port = "3000";

const cloud_url = "https://data.jyanganj.com";

const baseUrl = `${cloud_url}/v1`;

// const baseUrl = `${localhost}:${port}/v1`;

// Export the base URL
export default baseUrl;

import React, { useState, useEffect } from "react";
import baseUrl from "../Configs";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { useRecoilValue } from "recoil";
import {
  teacherSelectedClassState,
  teacherSelectedSectionState,
} from "../Atoms";

const EditExistingExam = (props) => {
  const [classes, setClasses] = useState([]);
  const [examName, setExamName] = useState("");
  const [existingSubjects, setExistingSubjects] = useState([]); // Existing subjects fetched from the server
  const [initialSubjects, setInitialSubjects] = useState([]); // To track initial dates
  const [selectedExam, setSelectedExam] = useState(null);
  const [exams, setExams] = useState([]); // Store all exams

  const [newSubjects, setNewSubjects] = useState([
    { subjectName: "", date: "", takenBy: "" },
  ]); // For adding new subjects
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);

  const getExamsUrl = `${baseUrl}/examroute/getexamsbyclassandsection?classname=${teacherSelectedClass}&section=${teacherSelectedSection}`;
  const addSubjectsUrl = `${baseUrl}/examroute/addsubjectsinexam`;
  const getClassesUrl = `${baseUrl}/classroute/getavailableclasses`;

  // Handle exam selection
  const handleExamChange = (event) => {
    const examName = event.target.value;
    setSelectedExam(examName);

    // Find the selected exam's subjects and update the state
    const exam = exams.find((e) => e.examName === examName);
    if (exam) {
      setExistingSubjects(exam.subjects || []);
      setInitialSubjects(exam.subjects || []); // Store initial subjects
      setExamName(examName);
    }
  };

  // Handle input change for new subjects
  const handleNewSubjectChange = (index, event) => {
    const values = [...newSubjects];
    values[index] = { ...values[index], subjectName: event.target.value };
    setNewSubjects(values);
    console.log("subject", newSubjects.length);
  };

  // Handle date change for new subjects
  const handleNewDateChange = (index, date) => {
    const values = [...newSubjects];
    values[index].date = date;
    setNewSubjects(values);
  };

  // Handle date change for existing subjects
  const handleExistingDateChange = (index, date) => {
    const updatedSubjects = [...existingSubjects];
    updatedSubjects[index] = { ...updatedSubjects[index], date: date };
    setExistingSubjects(updatedSubjects);
  };

  // Handle "takenBy" field change for new subjects
  const handleTakenByChange = (index, event) => {
    const values = [...newSubjects];
    values[index].takenBy = event.target.value;
    setNewSubjects(values);
  };

  // Add new subject
  const addNewSubjectField = () => {
    setNewSubjects([
      ...newSubjects,
      { subjectName: "", date: "", takenBy: "" },
    ]);
  };

  // Remove new subject
  const removeNewSubject = (index) => {
    const updatedSubjects = [...newSubjects];
    updatedSubjects.splice(index, 1);
    setNewSubjects(updatedSubjects);
  };

  let optionsForClass = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const groupClasses = (classes) => {
    return classes.reduce((groupedClasses, cls) => {
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  // Fetch existing exam and subjects
  useEffect(() => {
    fetch(getExamsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data)) {
          setExams(data); // Store all exams
        }
      })
      .catch((error) => console.error("Failed to fetch exams:", error));
  }, [teacherSelectedClass, teacherSelectedSection]);

  // Prepare updated subjects for submission
  const getUpdatedSubjects = () => {
    // Filter existing subjects to only include those with updated dates
    const updatedExistingSubjects = existingSubjects.filter(
      (subject, index) => subject.date !== initialSubjects[index].date
    );

    // Filter out new subjects that have empty fields
    const validNewSubjects = newSubjects.filter(
      (subject) => subject.subjectName && subject.date
    );

    return [...updatedExistingSubjects, ...validNewSubjects];
  };

  const handleSubmit = async () => {
    if (!teacherSelectedClass || !teacherSelectedSection || !examName) {
      setDialogMessage(
        "Please fill out all required fields: Class, Section, and Exam Name."
      );
      setDialogOpen(true);
      return;
    }

    const updatedSubjects = getUpdatedSubjects();

    if (updatedSubjects.length === 0) {
      setDialogMessage("No changes detected.");
      setDialogOpen(true);
      return;
    }

    // Prepare updated exam details
    const updatedExamDetails = {
      className: teacherSelectedClass,
      section: teacherSelectedSection,
      examName: examName,
      subjects: updatedSubjects, // Only send updated or valid subjects
    };

    fetch(addSubjectsUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(updatedExamDetails),
    })
      .then((response) => response.text())
      .then((textData) => console.log(textData))
      .then(props.onSubmit()) // Trigger callback after submit
      .catch((e) => console.log(e));
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    fetch(getClassesUrl, optionsForClass)
      .then((response) => response.json())
      .then((data) => setClasses(groupClasses(data)))
      .catch((error) => console.error("Failed to fetch classes:", error));
  }, []);

  return (
    <div className="editExamSection">
      <div className="inputSection">
        <FormControl fullWidth>
          <InputLabel id="exam-select-label">Select Exam</InputLabel>
          <Select
            labelId="exam-select-label"
            id="exam-select"
            value={selectedExam}
            onChange={handleExamChange}
          >
            {exams.map((exam, index) => (
              <MenuItem key={index} value={exam.examName}>
                {exam.examName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* Display and Edit Existing Subjects */}
      <div>
        <h3>Existing Subjects</h3>
        {existingSubjects.map((subject, index) => (
          <div key={index} className="inputSection">
            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
              <FormControl fullWidth>
                <TextField
                  label="Subject Name"
                  value={subject.subjectName}
                  disabled // Subject name should not be editable
                />
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date"
                    value={subject.date}
                    onChange={(date) => handleExistingDateChange(index, date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
          </div>
        ))}
      </div>

      {/* Add New Subjects */}
      <div>
        <h3>Add New Subjects</h3>
        {newSubjects.map((subject, index) => (
          <div key={index} className="inputSection">
            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
              {/* <FormControl fullWidth>
                <TextField
                  label="Subject Name"
                  value={subject.subjectName}
                  onChange={(e) => handleNewSubjectChange(index, e)}
                />
              </FormControl> */}

              <FormControl fullWidth>
                <InputLabel id="subject-select-label">Subject</InputLabel>
                <Select
                  labelId="subject-select-label"
                  id="subject-select"
                  value={subject.subjectName}
                  onChange={(e) => handleNewSubjectChange(index, e)}
                >
                  {teacherSelectedClass &&
                    teacherSelectedSection &&
                    classes
                      .filter(
                        (cls) => cls.className === teacherSelectedClass
                      )[0]
                      ?.sectionsAndSubjects?.filter(
                        (sAs) => sAs.section === teacherSelectedSection
                      )[0]
                      ?.subjects?.map((subject, idx) => (
                        <MenuItem key={idx} value={subject.subjectName}>
                          {subject.subjectName}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date"
                    value={subject.date}
                    onChange={(date) => handleNewDateChange(index, date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Taken By"
                  value={subject.takenBy}
                  onChange={(e) => handleTakenByChange(index, e)}
                />
              </FormControl>
              <IconButton
                color="secondary"
                onClick={() => removeNewSubject(index)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </div>
        ))}
        <div className="orange-button" onClick={addNewSubjectField}>
          Add More Subject
        </div>
      </div>

      {/* Submit Button */}
      <div className="addButton" onClick={() => handleSubmit()}>
        <p>Update Exam</p>
      </div>

      {/* Dialog for Error Message */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Missing Information"}
        </DialogTitle>
        <DialogContent>
          <p>{dialogMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditExistingExam;

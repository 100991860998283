import React, { useState, useEffect } from "react";
import baseUrl from "../Configs";

// import Dayrating from "./DayRating";
import "./Css/SelectClassView.css";
const SelectClassView = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const getClassesUrl = `${baseUrl}/classroute/getavailableclasses`;

  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  useEffect(() => {
    fetch(getClassesUrl, options)
      .then((response) => response.json())
      .then((data) => setClasses(groupClasses(data)))
      .catch((error) => console.error("Failed to fetch classes:", error));
  }, []);

  const groupClasses = (classes) => {
    return classes.reduce((groupedClasses, cls) => {
      // Find the class in the grouped classes
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      // If the class is not yet in the grouped classes, add it
      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sections: [cls.section],
        });
      } else {
        // Otherwise, add the section to the class in the grouped classes
        groupedClasses[classIndex].sections.push(cls.section);
      }
      return groupedClasses;
    }, []);
  };

  const selectClass = (className) => {
    setSelectedClass(className);
    setSelectedSection(null);
  };

  const selectSection = (section) => {
    setSelectedSection(section);
  };

  return (
    <div>
      {classes.map((cls, index) => (
        <div key={index}>
          <button onClick={() => selectClass(cls.className)}>
            {`Class ${cls.className}`}
          </button>
          {selectedClass === cls.className && (
            <div>
              {cls.sections.map((section, index) => (
                <button key={index} onClick={() => selectSection(section)}>
                  {`Section ${section}`}
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SelectClassView;

import React, { useState } from "react";
import baseUrl from "./Configs";

import "./Css/EntryPage.css";

import Register from "./Register";
import LoginForm from "./Login";
import RegisterHeader from "./RegisterHeader";
import Banner from "./Banner";

const EntryPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginUrl = `${baseUrl}/auth/login`;

  const handleLogin = async (event) => {
    event.preventDefault();

    const response = await fetch(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const data = await response.json();
      // Save the token in memory
      localStorage.setItem("token", data.token);
    } else {
      // Handle error
      console.error("Failed to login");
    }
  };

  return (
    <div className="entrypage-container">
      <div className="entrypage-header">
        <RegisterHeader />
      </div>
      <div className="entrypage-banner">
        <Banner />
      </div>
      <div className="entrypage-content">
        <div className="entrypage-register">
          <Register />
        </div>
        <div className="entrypage-login">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default EntryPage;

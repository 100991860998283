import React from "react";
import { RecoilRoot } from "recoil";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./Css/App.css";
import StudentTable2 from "./StudentTable2";
import AddStudent from "./AddStudent";
import Class from "./Teacher-app/Class";
import InputData from "./InputData";
import SelectClassView from "./Teacher-app/SelectClassView";
import TeacherHome from "./Teacher-app/TeacherHome";
import AdminTasks from "./AdminTasks";
import AddClass from "./AddClass";
import EntryPage from "./EntryPage";
import MasterUserTasks from "./MasterUserTasks";
import SuperUserHome from "./SuperUserHome";
import ThankyouPage from "./ThankyouPage";
import AddNewSyllabus from "./AddNewSyllabus";
import LiveSyllabus from "./Teacher-app/LiveSyllabus";
import StudentFeeStatus from "./StudentFee";
import SalaryStatus from "./SalaryStatus";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<EntryPage />} />
          {/* <Route path="/d" element={<StudentDetail />} /> */}
          <Route path="/t" element={<StudentTable2 />} />
          <Route path="/input" element={<InputData />} />
          <Route path="/class" element={<Class />} />
          <Route path="/entrypage" element={<EntryPage />} />
          <Route path="/master" element={<MasterUserTasks />} />
          <Route path="/super" element={<SuperUserHome />} />
          <Route path="/selectclass" element={<SelectClassView />} />
          <Route path="/teacherhome" element={<TeacherHome />} />
          <Route path="/admintasks" element={<AdminTasks />} />
          <Route path="/addstudent" element={<AddStudent />} />
          <Route path="/addclass" element={<AddClass />} />
          <Route path="/thankyou" element={<ThankyouPage />} />
          <Route path="/add-new-syllabus" element={<AddNewSyllabus />} />
          <Route path="/livesyllabus" element={<LiveSyllabus />} />
          <Route path="/feeStatus" element={<StudentFeeStatus />} />
          <Route path="/salaryStatus" element={<SalaryStatus />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import baseUrl from "../Configs";

import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import "../Css/StudentTable2.css";
import { useRecoilState } from "recoil";
import {
  receivedStudentListState,
  teacherSelectedSectionState,
  teacherSelectedClassState,
  teacherSelectedDateState,
  teacherSelectedSubjectState,
} from "../Atoms";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import "./Css/Header.css";

const TeacherHeader = () => {
  const [classes, setClasses] = useState([]);
  const [selectedSubject, setSelectedSubject] = useRecoilState(
    teacherSelectedSubjectState
  );
  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [selectedDate, setTeacherSelectedDate] = useRecoilState(
    teacherSelectedDateState
  );

  const [receivedStudentList, setReceivedStudentList] = useRecoilState(
    receivedStudentListState
  );

  const [receivedClassDetails, setReceivedClassDetails] = useRecoilState(
    receivedStudentListState
  );

  let getStudentListUrl = (section) =>
    `${baseUrl}/classroute/getstudentlist?className=${selectedClass}&section=${section}`;
  let getClassInfoUrl = `${baseUrl}/classroute/getclass?className=${selectedClass}&section=${selectedSection}`;
  const getClassesUrl = `${baseUrl}/classroute/getavailableclasses`;

  let classDetails = {
    className: selectedClass,
    section: selectedSection,
  };
  let subjectDetails = {
    subjectName: selectedSubject,
  };

  let options = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const handleClassChange = (value) => {
    console.log(value);
    setSelectedClass(value);
  };

  const handleSubjectChange = (value) => {
    setSelectedSubject(value);
  };

  const handleSectionChange = (value) => {
    setSelectedSection(value);
    getStudentList(value);
    console.log(classes);
  };

  const handleDateChange = (e) => {
    setTeacherSelectedDate(new Date(e));
    console.log(selectedDate);
  };

  // const getClassDetails = async () => {
  //   try {
  //     const response = await fetch(getClassInfoUrl, options);
  //     const data = await response.json();
  //     console.log(data);
  //     setReceivedClassDetails(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getStudentList = async (section) => {
    try {
      const response = await fetch(getStudentListUrl(section), options);
      const data = await response.json();
      console.log(data);
      setReceivedStudentList(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch(getClassesUrl, options)
      .then((response) => response.json())
      .then((data) => setClasses(groupClasses(data)))
      .then(console.log(classes))
      .catch((error) => console.error("Failed to fetch classes:", error));
  }, []);

  const groupClasses = (classes) => {
    return classes.reduce((groupedClasses, cls) => {
      // Find the class in the grouped classes
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      // If the class is not yet in the grouped classes, add it
      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        // Otherwise, add the section and subjects to the class in the grouped classes
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  return (
    <div className="teacher-header_container student-table-2_parent">
      <div className="teacher-header_logo header-container">
        <h3>SpellStudy</h3>
      </div>
      <div className="teacher-header_selector header-selector">
        <FormControl fullWidth>
          <InputLabel id="class-select-label">Select Class</InputLabel>
          <Select
            labelId="class-select-label"
            id="class-select"
            value={selectedClass}
            onChange={(e) => handleClassChange(e.target.value)}
          >
            {classes.map((cls, index) => (
              <MenuItem key={index} value={cls.className}>
                {cls.className}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="section-select-label">Select Section</InputLabel>
          <Select
            labelId="section-select-label"
            id="section-select"
            value={selectedSection}
            onChange={(e) => handleSectionChange(e.target.value)}
          >
            {selectedClass &&
              classes
                .find((cls) => cls.className === selectedClass)
                .sectionsAndSubjects.map((sAs, index) => (
                  <MenuItem key={index} value={sAs.section}>
                    {sAs.section}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="subject-select-label">Select Subject</InputLabel>
          <Select
            labelId="subject-select-label"
            id="subject-select"
            value={selectedSubject}
            onChange={(e) => handleSubjectChange(e.target.value)}
          >
            {selectedClass &&
              selectedSection &&
              classes
                .find((cls) => cls.className === selectedClass)
                ?.sectionsAndSubjects.find(
                  (sAs) => sAs.section === selectedSection
                )
                ?.subjects.map((subject, index) => (
                  <MenuItem key={index} value={subject.subjectName}>
                    {subject.subjectName}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Date"
            inputFormat="dd/MM/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default TeacherHeader;
